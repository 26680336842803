import { COLOR, StyledAppButton } from "../constants";
import { IEvent } from "../interfaces";

interface IEventSelectorProps {
    events: IEvent[]
    onChange: (index: number, value: boolean) => void
    onExit: () => void
}

function EventSelector(props: IEventSelectorProps): React.ReactElement {
    function renderEvents(): React.ReactElement[] {
        const elements: React.ReactElement[] = [];
    
        for (let i = 0; i < props.events.length; i++) {
            elements.push(<StyledAppButton key={`category-${i}`} 
                style={{ background: props.events[i].isSelected ? COLOR.ARMY_GOLD : '' }}
                onClick={() => {
                    if (props.events[i].isSelected)
                        props.onChange(i, false);
                    else
                        props.onChange(i, true);
                }}
                text={props.events[i].name}
            />)
        }
    
        return elements;
    }

    return (
        <div>
            {renderEvents()}
            <div style ={{height: '3vh'}}></div>
            <StyledAppButton 
                onClick={props.onExit} 
                text={'DONE'} />
        </div>
    )
}

export default EventSelector;