import { ReactElement } from "react";
import { COLOR } from "../constants";

function Editor(): ReactElement {
    return (
        <div style={{height: '100%', width: '100%'}}>
            <div style= {{ textAlign: 'center', display: 'flex', flexDirection: 'row', color: 'white', width: '100%', fontSize: '24px', top: '5px',
                borderBottom: `3px solid ${COLOR.ARMY_GOLD}`, justifyItems: 'center' }}>AmmoOp Editor
            </div>
        </div>
    )
}

export default Editor;