import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import React from 'react';

interface IIteratorProps {
    onChange: (value: number) => void
    currValue: number
}

function Iterator(props: IIteratorProps): React.ReactElement {    
    return (
        <div style={{ display: 'flex', margin: 'auto', alignItems: 'center', fontSize: '24px' }}>
            <FastRewindIcon style={{ marginRight: '10px', fontSize: '32px' }} onClick={() => {
                    if (props.currValue > 5) props.onChange(props.currValue - 5)
                    if (props.currValue <= 5) props.onChange((props.currValue+1) - props.currValue)
                }}>
            </FastRewindIcon>
            <RemoveCircleOutlineIcon style={{ marginRight: '10px', fontSize: '32px' }} onClick={() => {
                    if (props.currValue > 1) props.onChange(props.currValue - 1)
                }}>
            </RemoveCircleOutlineIcon>
            {props.currValue}
            <AddCircleOutlineIcon style={{ marginLeft: '10px', fontSize: '32px' }} onClick={() => {props.onChange(props.currValue + 1)}}></AddCircleOutlineIcon>
            <FastForwardIcon style={{ marginLeft: '10px', fontSize: '32px' }} onClick={() => {props.onChange(props.currValue + 5)}}></FastForwardIcon>
        </div>
    )
}

export default Iterator