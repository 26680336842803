import { APP_FOOTER_HEIGHT, COLOR, MODAL_STYLE_FIT_CONTENT, STR_APP_FOOTER_BUG_SUBMISSIONS_MESSAGE, STR_PROJECT_MANAGER_EMAIL, 
    STR_PROJECT_MANAGER_INFO } from "../constants";
import Modal from 'react-modal';
import React from "react";

function AppFooter(): React.ReactElement {
    const [isModalOpen, setModalOpen] = React.useState<boolean>(false);

    return (
        <div style={{ display: 'flex', position: 'fixed', bottom: '0px', left: '0px', right: '0px', 
        minHeight: `${APP_FOOTER_HEIGHT}px`, background: COLOR.ARMY_GOLD, cursor: 'pointer' }} onClick={() => {
                setModalOpen(!isModalOpen);
            }}>
            <div style={{ margin: 'auto' }}>{STR_APP_FOOTER_BUG_SUBMISSIONS_MESSAGE}</div>
            <Modal
                isOpen={isModalOpen}
                ariaHideApp={false}
                onRequestClose={() => {setModalOpen(false)}}
                style={MODAL_STYLE_FIT_CONTENT}
                contentLabel="PM Information"
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>{STR_PROJECT_MANAGER_INFO}</div>
                    <a href={`mailto: ${STR_PROJECT_MANAGER_EMAIL}`}>{STR_PROJECT_MANAGER_EMAIL}</a>
                </div>
            </Modal>
        </div>
    )
}

export default AppFooter;